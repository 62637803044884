(function ($) {

    // ENABLE DISABLE SCROLL
    $.fn.disableScroll = function () {
        window.oldScrollPos = $(window).scrollTop();

        $(window).on('scroll.scrolldisabler', function (event) {
            $(window).scrollTop(window.oldScrollPos);
            event.preventDefault();
        });
    };
    $.fn.enableScroll = function () {
        $(window).off('scroll.scrolldisabler');
    };


    var app = {
        headerLoad: function () {
            var sticky = new Waypoint.Sticky({
                element: $('header.banner')[0]
            });
            var $header = $('header.banner');
            var $head = $('nav.navbar');
            $('.separator').each(function (i) {
                var $el = $(this),
                    animClassDown = $el.data('animateDown'),
                    animClassUp = $el.data('animateUp');

                $el.waypoint(function (direction) {
                    if (direction === 'down' && animClassDown) {
                        $head.attr('class', 'navbar ' + animClassDown);
                        $header.addClass('down');
                    }
                    else if (direction === 'up' && animClassUp) {
                        $head.attr('class', 'navbar ' + animClassUp);
                        $header.removeClass('down');
                    }
                }, { offset: '0' });
            });
        },
        headerResize: function(){
            var HeightApply = $('header.banner').height();
            $('.fixed-wrapper').css("height", HeightApply);

        },
        twitterLoad: function(){

            if ($("#twitter-feed").length) {

                var configProfile = {
                    "profile": { "screenName": 'bidwritingcom' },
                    "domId": 'twitter-feed',
                    "maxTweets": 1,
                    "enableLinks": true,
                    "showUser": false,
                    "showTime": false,
                    "showImages": false,
                    "lang": "en"
                };
                twitterFetcher.fetch(configProfile);
            }
        },
        shareLoad: function () {

            function shareSystem() {

                var optionsOverlay = {
                    modifier: 'shareoverlay'
                };
                var shareModal = $('[data-share=overlay]').overlay(optionsOverlay);

                var openShare = $('[data-share=open]'),
                    inputShare = $('#share-input'),
                    sharesubmit = $('#sharesubmit');

                openShare.click(function () {
                    shareModal.open();
                    $('.share-overlay').show();
                });

                var link = location.href;
                var title = document.title;
                $('a.js-linkedin').click(function () {
                    event.preventDefault();
                    window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + link + "&title=" + title + "&summary=&source=Bidwriting", 'Share', 'left=20,top=20,width=600,height=400');
                    shareModal.close();
                    return false;
                });
                $('a.js-facebook').click(function () {
                    event.preventDefault();
                    window.open("https://www.facebook.com/sharer/sharer.php?u=" + link, 'Share', 'left=20,top=20,width=600,height=400');
                    shareModal.close();
                    return false;
                });
                $('a.js-twitter').click(function () {
                    event.preventDefault();
                    window.open("https://twitter.com/intent/tweet?url=" + link + "&via=bidwritingcom&text=" + title, 'Share', 'left=20,top=20,width=600,height=400');
                    shareModal.close();
                    return false;
                });
                $('a.mailto').click(function () {
                    event.preventDefault();
                    window.location.href = "mailto:?subject=" + title + "&body=" + link;
                    shareModal.close();
                    return false;

                });



            }

            shareSystem();
        },
        searchLoad: function () {
            function searchSystem() {

                function hasParent(e, p) {
                    if (!e) { return false; }
                    var el = e.target || e.srcElement || e || false;
                    while (el && el !== p) {
                        el = el.parentNode || false;
                    }
                    return (el !== false);
                }


                var triggerBttnSearch = document.querySelector('a[data-search=open-overlay]'),
                menucontainerSearch = document.querySelector('.search-overlay'),
                isSearchOpen = false;

                function toggleSearch() {
                    if (isSearchOpen) {
                        classie.remove(menucontainerSearch, 'overlay-is-initialized');
                        $("body").enableScroll();
                        $('body').unbind('touchmove');
 
                        $('.search-overlay').removeClass('overlay-is-opened');
                        $('.search-overlay').addClass('overlay-is-closing');
                        $('.overlay-overlay').removeClass('overlay-is-opened');
                        $('.overlay-overlay').addClass('overlay-is-closing');
                        setTimeout(function () {
                            $('.search-overlay').removeClass('overlay-is-closing');
                            $('.search-overlay').addClass('overlay-is-closed');
                            $('.search-overlay').css('display', 'none');
                            $('.search-wrapper').hide();
                            $('.overlay-overlay').removeClass('overlay-is-closing');
                            $('.overlay-overlay').addClass('overlay-is-closed');
                            $('.overlay-overlay').hide();
                        }, 500);
                    }
                    else {
                        classie.add(menucontainerSearch, 'overlay-is-initialized');
                        $("body").disableScroll();
                        $('body').bind('touchmove', function (e) {
                            e.preventDefault();
                        });
                        $('.search-wrapper').show();
                        $('.overlay-overlay').show();
                        $('.overlay-overlay').removeClass('overlay-is-closed');
                        $('.overlay-overlay').addClass('overlay-is-opening');
                        $('.search-overlay').css('display', 'inline-block');
                        $('.search-overlay').removeClass('overlay-is-closed');
                        $('.search-overlay').addClass('overlay-is-opening');
                        setTimeout(function () {
                            $('.overlay-overlay').removeClass('overlay-is-opening');
                            $('.overlay-overlay').addClass('overlay-is-opened');
                            $('.search-overlay').removeClass('overlay-is-opening');
                            $('.search-overlay').addClass('overlay-is-opened');
                        }, 500);


                    }
                    isSearchOpen = !isSearchOpen;
                }

                function initEventsSearch() {
                        triggerBttnSearch.addEventListener('click', toggleSearch);
                        $('.backsearch').click(function (e) {
                            toggleSearch();
                        });
                        document.onkeydown = function (evt) {
                            if (isSearchOpen) {
                                evt = evt || window.event;
                                if (evt.keyCode == 27) {
                                    toggleSearch();
                                }
                            }
                        };

                }

                function initSearch() {
                    initEventsSearch();
                }



                function closeSearch() {
                    toggleSearch();
                    isSearchOpen = !isSearchOpen;
                }

                initSearch();

                var openSearch = $('[data-search=open]'),
                    inputSearch = $('#search-input'),
                    searchsubmit = $('#searchsubmit');

                openSearch.click(function () {
                    setTimeout(function () {
                        inputSearch.focus();
                    }, 1000);
                });

            }

            searchSystem();
        },
        slidersLoad: function () {

            // HOME SLIDER

            $('.slider__home').slick({
                infinite: true,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                fade: true,
                speed: 900,
                pauseOnHover: false,
                arrows: false,
                dots: false
            });


            // 	HERO SLIDER

            $('.cslider').slick({
                infinite: true,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                speed: 900,
                pauseOnHover: false,
                centerMode: true,
                centerPadding: '60px',
                arrows: false,
                dots: true
            });
            $('.cslider').on('click', '.slick-slide', function (e) {
                e.stopPropagation();
                $('.slick-slider').slick('slickNext');
            });

            // SECTORS SLIDER

            $('.count').slick({
                infinite: true,
                slidesToShow: 9,
                autoplaySpeed: 5000,
                autoplay: true,
                speed: 700,
                pauseOnHover: true,
                arrows: false,
                dots: false,
                draggable: false,
                asNavFor: '.start__slider'
            });
            $('.start__slider').slick({
                infinite: true,
                slidesToShow: 1,
                autoplaySpeed: 5000,
                autoplay: true,
                draggable: false,
                speed: 700,
                pauseOnHover: true,
                arrows: true,
                dots: false,
                asNavFor: '.count',
                prevArrow: '<span class="prev-arrow"></span>',
                nextArrow: '<span class="next-arrow"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64.9 113.5" style="enable-background:new 0 0 64.9 113.5;" xml:space="preserve"><g><path fill="#ffffff" d="M35.7,112.7l29-55.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3 c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1l-29-55.2c-0.4-0.8-1.4-1.1-2.3-0.7c-0.6,0.3-1,0.8-1,1.4c0,0.2,0.1,0.5,0.2,0.7l28.7,54.5 l-28.7,54.5c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.6,0.4,1.1,1,1.4C34.3,113.8,35.3,113.4,35.7,112.7z"/><path fill="#ffffff" d="M3.3,112.7l29-55.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3 c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1L3.3,0.9C2.9,0.1,1.8-0.2,1,0.2C0.4,0.4,0,1,0,1.6C0,1.8,0.1,2,0.2,2.2l28.7,54.5L0.2,111.3 C0.1,111.5,0,111.8,0,112c0,0.6,0.4,1.1,1,1.4C1.8,113.8,2.9,113.4,3.3,112.7z"/></g></svg></span>'
            });
            $('.count').on('click touchend', '.slick-slide', function (e) {
                e.stopPropagation();
                var index = $(this).data("slick-index");
                if ($('.slick-slider').slick('slickCurrentSlide') !== index) {
                    $('.slick-slider').slick('slickGoTo', index);
                }
            });
        },
        menuMobile: function () {

                function hasParent(e, p) {
                    if (!e) { return false; }
                    var el = e.target || e.srcElement || e || false;
                    while (el && el !== p) {
                        el = el.parentNode || false;
                    }
                    return (el !== false);
                }


                var triggerBttn = document.querySelector('.menu-button'),
                menucontainer = document.querySelector('nav.mb-menu'),
                header = document.querySelector('header.banner'),
                linkBttn = menucontainer.querySelector('li.menu-item'),
                isMenuOpen = false;

                function toggleMenu() {
                    if (isMenuOpen) {
                        classie.remove(menucontainer, 'opened');
                        classie.remove(triggerBttn, 'menu-button--open');
                        $('html').css('overflow-y', 'scroll');
                        $('body').unbind('touchmove');
                    }
                    else {
                        classie.add(menucontainer, 'opened');
                        classie.add(triggerBttn, 'menu-button--open');
                        $('html').css('overflow', 'hidden');
                        $('body').bind('touchmove', function (e) {
                            e.preventDefault();
                        });

                    }
                    isMenuOpen = !isMenuOpen;
                }

                function initEventsMenu() {
                    triggerBttn.addEventListener('click', toggleMenu);
                }

                function init() {
                    initEventsMenu();
                }



                function closeMenus() {
                    toggleMenu();
                    isMenuOpen = !isMenuOpen;
                }

                init();

        },
        mapLoad: function () {
            $('a[data-map=toggle]').click(function () {
                $('.map-container').toggleClass('active');
            });
            (function ($) {
                function new_map($el) {
                    var $markers = $el.find('.marker');

                    var args = {
                        zoom: 19,
                        center: new google.maps.LatLng(0, 0),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    };

                    var map = new google.maps.Map($el[0], args);

                    map.markers = [];
                    $markers.each(function () {
                        add_marker($(this), map);

                    });


                    center_map(map);
                    return map;

                }


                function add_marker($marker, map) {

                    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
                    var iconmarker = $marker.attr('data-icon');

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        icon: iconmarker
                    });

                    map.markers.push(marker);

                    if ($marker.html()) {
                        var infowindow = new google.maps.InfoWindow({
                            content: $marker.html()
                        });

                    }

                }

                function center_map(map) {

                    var bounds = new google.maps.LatLngBounds();

                    $.each(map.markers, function (i, marker) {
                        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                        bounds.extend(latlng);

                    });

                    if (map.markers.length == 1) {
                        map.setCenter(bounds.getCenter());
                        map.setZoom(10);
                    }
                    else {
                        map.fitBounds(bounds);
                    }
                }

                var map = null;
                $(document).ready(function () {
                    $('#map-canvas').each(function () {
                        map = new_map($(this));
                    });

                });
            })(jQuery);
        }


    };
    var UTIL = {
        loadEvents: function () {
            setTimeout(function () {
                app.mapLoad();
            }, 1000);
            app.headerLoad();
            app.shareLoad();
            app.searchLoad();
            app.menuMobile();
            app.slidersLoad();
            app.twitterLoad();
            $(document).ready(function () {
                $(this).scrollTop(0);
            });
        }
    };
    $(document).ready(UTIL.loadEvents);
    $( window ).resize(function() {
        app.headerResize();
    });

})(jQuery);